import React from "react"
import { graphql, Link } from "gatsby"
import {
  Breadcrumb,
  BreadcrumbItem,
  Icon,
  Text,
  PseudoBox,
  Heading,
  Divider,
  List,
  ListItem,
} from "@chakra-ui/core"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import RecipeTemplate from "./blogRecipeTemplate.js"
import { Carousel } from "react-responsive-carousel"
const crumbColor = "GOLDENROD"

const blogTypeRecipes = {
  recipes: RecipeTemplate,
}

export default function Template({ data }) {
  const post = data.markdownRemark
  const {
    title,
    author,
    date,
    blogType,
    path,
    images,
    ingredients,
    instructions
  } = post.frontmatter
  return (
    <Layout>
      <PseudoBox color={crumbColor} _hover={{ textDecoration: "underline" }}>
        <Link to="/blog">&lt; Back to Blogs</Link>
      </PseudoBox>
      <Heading marginTop="8px">{title}</Heading>
      <Text color="gray.500">{date}</Text>
      <Divider />
      <Carousel showThumbs={false}>
        {images.map((image, key) => (
          <Img key={key} fluid={image.childImageSharp.fluid} />
        ))}
      </Carousel>
      <Divider />
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <Divider />
      <Divider />
      <Heading size="md">Ingredients</Heading>
      <List styleType="disc">
        {ingredients.map((ingredient, key) => (
          <ListItem key={key}>{ingredient}</ListItem>
        ))}
      </List>
      <Divider />
      <Divider />
      <Heading size="md">Instructions</Heading>
      <List styleType="decimal">
        {instructions.map((instruction, key) => (
          <ListItem key={key}>{instruction}</ListItem>
        ))}
      </List>
      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <Divider />
      <Divider />
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPost($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        author
        date(formatString: "MMMM DD, YYYY")
        title
        path
        tags
        blogType
        ingredients
        instructions
        images {
          childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 700, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
